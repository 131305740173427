<template>
  <div>
    <b-card
      no-body
      class="card-banner-ads h-100"
    >
      <b-card-body
        class="cardImpression"
      >
        <h4>Business Impression Info</h4>
        <b-card
          no-body
          class="card-banner-ads h-100"
        >
          <b-card-body
            class="p-0 pt-2"
          >
            <div
              class="iconClassDiv p-0"
            >
              <feather-icon
                icon="TvIcon"
                size="20"
                class="iconClass"
              />
            </div>
            <b-col
              class="ml-0 mb-2"
            >
              <b-row>
                <b-col class="pl-0 d-flex justify-content-between">
                  <h4 class="mb-0 text-primary ">
                    Free Impressions :
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="px-0 pl-1 d-flex justify-content-between textStyle pt-4">
                  <p>
                    Remaining Impressions :
                  </p>
                  <p class="mb-0 pl-0 textStyle">
                    {{ userGroupImpressionsDetail ? Math.floor(userGroupImpressionsDetail[0].free_impressions) : '0' }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-card-body>
        </b-card>
        <div class="px-1">
          <hr class="mb-2">
        </div>
        <b-card
          no-body
          class="card-banner-ads h-100"
        >
          <b-card-body
            class="p-0 pt-2"
          >
            <div
              class="iconClassDiv"
            >
              <feather-icon
                icon="CreditCardIcon"
                size="20"
                class="iconClass"
              />
            </div>
            <b-col
              class="ml-0 mb-2"
            >
              <b-row>
                <b-col class="pl-0 d-flex justify-content-between">
                  <h4 class="mb-0 text-primary ">
                    Paid Impressions :
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="px-0 pl-1 d-flex justify-content-between textStyle pt-4">
                  <p>
                    Remaining Impressions :
                  </p>
                  <p class="mb-0 pl-0 textStyle">
                    {{ userGroupImpressionsDetail ? Math.floor(userGroupImpressionsDetail[0].paid_impressions) : '0' }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-card-body>
          <h5 />
        </b-card>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardBody,
  //   BCardTitle,
  VBTooltip,
  //   BCardHeader,
  BRow, BCol,
} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

Vue.use(Vuesax)
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    // BCardTitle,
    // BCardHeader,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      bannerCampaignLoading: false,
      allBannerCampaigns: [],
      campaign: '',
      userGroupImpressionsDetail: null,
    }
  },
  computed: {
    getPermissionOfMember() {
      return this.$store.getters['user/getPermissionOfMember'](MemberPermissions.INTERNAL_NETWORK_ADS)
    },
    getUserDetails() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
  },
  mounted() {
    this.bannerCampaignLoading = true
    if (this.getUserDetails.user_has_group.resources) {
      this.userGroupImpressionsDetail = this.getUserDetails.user_has_group.resources
    }
    this.bannerCampaignLoading = false
  },
  methods: {
  },
}
</script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .card-banner-ads{
    margin-bottom: 0;
  }
  .switch {
    width: 50px;
    border-radius: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #00E265;
    color: white;
  }
  .sliderImage{
    height: 170px !important;
  }
  .links{
      text-decoration: underline !important;
      margin-top: 8px !important;
      padding-top: 0px !important;
  }
  .heading{
    padding-top: 10px !important;
  }
  .switchs {
    width: 50px;
    padding-inline: 40px;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    // background-color: #7FF0B2;
    color: white;
  }
  .swiperImage{
    width: 100px;
    height: 100px;
  }
  .campaignDiv{
    min-height: 28px;
  }
  .noCampaign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  .textStyle{
    font-size: small;
  }
  .iconClass{
    color: blue
  }
  .iconClass{
    .iconClass {
        color: #4697f3; /* Purple color for the icon */
      }
  }
  .iconClassDiv{
    width: 50px; /* Adjust to your preferred size */
    height: 50px; /* Make it a perfect square */
    background-color: rgba(68, 116, 247, 0.158); /* Light purple background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center; /* Centers the icon vertically */
    justify-content: center; /* Centers the icon horizontally */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional for a soft shadow */
    position: absolute;
    left: 220px;
    top: 5px;
}
.cardImpression{
    background-color: #a1e9f309;
}

  </style>
